import { request } from "../utils/axios-utils";

const namespace = "/api/operation";

export const getTransactionsToClassify = (year, month) => {
    return request({
        url: namespace + "s/" + year + "/" + month,
        method: "get",
    });
};

export const updateTransaction = (payload: any, _id: string) => {
    console.log(payload);
    return request({
        url: namespace + `s/update`,
        method: "post",
        data: payload,
    });
};
