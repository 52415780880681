import React, { useState } from "react";
import AccountingsSettings from "../Settings/AccountingsSettings";
import { Categorization } from "./Categorization";
import { EBICSTList } from "./EBICSTList";

export const OperationsCheck = () => {
    const [activeTab, setActiveTab] = useState("ebicsT");

    return (
        <div className="max-w-8xl mx-auto p-6">
            <div className="mb-6 border-b border-gray-200">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                    {[
                        { key: "ebicsT", label: "Réception des opérations" },
                        // { key: "ebicsTS", label: "Ordre de virement" },
                        { key: "categorisation", label: "Paramétrage" },
                        { key: "connexions", label: "Connexions" },
                    ].map((tab) => (
                        <button
                            key={tab.key}
                            onClick={() => setActiveTab(tab.key)}
                            className={`whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm ${
                                activeTab === tab.key
                                    ? "border-indigo-600 text-indigo-600"
                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                            }`}
                        >
                            {tab.label}
                        </button>
                    ))}
                </nav>
            </div>
            {activeTab === "ebicsT" && <EBICSTList />}
            {/* {activeTab === "ebicsTS" && <EBICSTSList />} */}
            {activeTab === "categorisation" && <Categorization />}
            {activeTab === "connexions" && <AccountingsSettings />}
        </div>
    );
};
