import React, { useState } from "react";
import InputComponent from "./InputComponent";

const ListSubCategories: React.FC<{
    remove: Function;
    cancel: Function;
    editSubCategory: Function;
    updateSubCategory: Function;
    subCategoryInEdit: any;
    listOfSubCategories: Array<any>;
}> = (props) => {
    const [newSubCategoryValue, setNewSubCategoryValue] = useState<string>(
        props.subCategoryInEdit
    );

    const isScrollable = props.listOfSubCategories.length > 10;

    return (
        <div className="w-full">
            <div
                className={`overflow-x-auto ${
                    isScrollable ? "max-h-96 overflow-y-auto" : ""
                }`}
            >
                <table className="min-w-full table-auto">
                    <tbody>
                        {props.listOfSubCategories.map(
                            (l: any, index: number) =>
                                !props.subCategoryInEdit &&
                                l !== props.subCategoryInEdit && (
                                    <tr
                                        key={index}
                                        className={`border-t ${
                                            index % 2 === 0 ? "bg-gray-100" : ""
                                        } flex justify-between`}
                                    >
                                        <td className="px-4 py-2">{l}</td>
                                        <td className="px-4 py-2">
                                            {![
                                                "Revenus fonciers",
                                                "Coupons",
                                                "Dividendes",
                                                "Intérêts",
                                                "Vente immobilière",
                                                "Vente mobilier",
                                                "Vente de titres",
                                                "Prime d'activités",
                                                "A.A.H.",
                                                "A.P.A.",
                                                "A.S.P.A.",
                                                "R.S.A.",
                                                "Fermage",
                                                "Pension de retraite",
                                                "Pension invalidité",
                                                "Pension alimentaire",
                                                "Pension militaire",
                                                "Rente viagère",
                                                "Rentes",
                                                "Salaire",
                                                "BIC",
                                                "BNC",
                                                "Gérant associés",
                                                "Revenu hors de france",
                                            ].includes(l) && (
                                                <>
                                                    <button
                                                        onClick={() => {
                                                            props.editSubCategory(
                                                                l
                                                            );
                                                            setNewSubCategoryValue(
                                                                l
                                                            );
                                                        }}
                                                        className="text-blue-500 hover:text-blue-700 mr-2"
                                                    >
                                                        Modifier
                                                    </button>
                                                    <button
                                                        onClick={() =>
                                                            props.remove(l)
                                                        }
                                                        className="text-red-500 hover:text-red-700"
                                                    >
                                                        Supprimer
                                                    </button>
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                )
                        )}
                    </tbody>
                </table>
            </div>
            {props.subCategoryInEdit && (
                <div className="mt-4 flex items-center">
                    <button
                        onClick={() => props.cancel()}
                        className="text-gray-500 hover:text-gray-700 mr-4"
                    >
                        Annuler
                    </button>
                    <span className="text-lg font-semibold">
                        Modification de la sous-catégorie :{" "}
                        {props.subCategoryInEdit}
                    </span>
                    <div className="ml-4 flex items-center">
                        <InputComponent
                            id="idSubCatEditing"
                            className="border border-gray-300 rounded-md p-2"
                            required
                            value={newSubCategoryValue}
                            onChange={(e) =>
                                setNewSubCategoryValue(e.target.value)
                            }
                            minLength={3}
                            maxLength={50}
                        />
                        <button
                            type="button"
                            className="ml-2 bg-blue-500 text-white px-4 py-2 rounded-md"
                            onClick={() => {
                                props.updateSubCategory(
                                    newSubCategoryValue,
                                    props.subCategoryInEdit
                                );
                                setNewSubCategoryValue("");
                            }}
                        >
                            Modifier
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ListSubCategories;
