import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { capitalizeFirstLetterOnly } from "../../../constants/Utils";
import ILinkedLabelWithCategory from "../../../interfaces/ILinkedLabelWithCategory";
import { request } from "../../../utils/axios-utils";
import FormAddLabelWithSubCategories from "../../layout/forms/FormAddLabelWithSubCategories";
import FormAddSubCategories from "../../layout/forms/FormAddSubCategories";
import ListLinkLabelsWithCategories from "../../layout/forms/ListLinkLabelsWithCategories";
import ListSubCategories from "../../layout/forms/ListSubCategories";
import Loader from "../../layout/Loader";

export const Categorization = () => {
    let searchBySelect = false;
    const [listOfSubCategories, setListOfSubCategories] =
        useState<Array<any>>(null);
    const [selectedCategory, setSelectedCategory] = useState<number>(0);
    const [editingSubCategoryValue, setEditingSubCategoryValue] =
        useState<string>(null);
    const [listSubCategoriesForSelect, setListSubCategoriesForSelect] =
        useState<Array<any>>(null);
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
    const [locks, setLocks] = useState<Array<boolean>>(null);
    const { addToast } = useToasts();

    // Other functions
    const actualiseLocks = () => {
        request({ addToast, url: `/api/accounts/locks` })
            .then(({ data }) => {
                setLocks(data);
            })
            .catch();
    };
    const [
        editingLinkedLabelWithCategory,
        setEditingLinkedLabelWithCategoryValue,
    ] = useState<ILinkedLabelWithCategory>(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState<any>(null);
    const [listOfLinkedLabels, setListOfLinkedLabels] =
        useState<Array<any>>(null);

    const updateSubCategories = (c: number) => {
        request({ addToast, url: `/api/users/subCategories/${c}` })
            .then(({ data }) => {
                setListOfSubCategories(data);
            })
            .catch();
    };

    const updateSubCategory = (
        NewSubcategoryName: any,
        subCategoryToChange: any
    ) => {
        const CFsubCategoryToChange =
            capitalizeFirstLetterOnly(subCategoryToChange);
        const CFNewSubcategoryName =
            capitalizeFirstLetterOnly(NewSubcategoryName);

        request({
            addToast,
            url: `/api/subCategories/update`,
            method: "post",
            data: {
                category: selectedCategory,
                subCategory: CFsubCategoryToChange,
                newSubcategoryName: CFNewSubcategoryName,
            },
        })
            .then((data) => {
                // The data has been saved.
                addToast(`La sous-catégorie a bien été mise à jour`, {
                    appearance: "success",
                });
                updateSubCategories(selectedCategory);
                setEditingSubCategoryValue(null);
            })
            .catch();
    };

    const createSubCategory = (category: number, subCategory: string) => {
        const CFsubCategory = capitalizeFirstLetterOnly(subCategory);
        request({
            addToast,
            url: "/api/subCategories/store",
            method: "post",
            data: {
                category: category,
                subCategory: CFsubCategory,
            },
        })
            .then((data) => {
                // The data has been saved.
                addToast(`La sous catégorie a bien été ajoutée`, {
                    appearance: "success",
                });
                updateSubCategories(category);
            })
            .catch();
    };
    const getSelectedLinkedSubCat = (c: string, label?: string) => {
        if (label != null) updateLinkedLabels(parseInt(c), label);
        else searchBySelect = true;
        updateSubCategoriesForSelect(parseInt(c));
    };
    const showEditLinkedLabelWithCategory = (
        linkedLabelWithCategory: ILinkedLabelWithCategory
    ) => {
        setEditingLinkedLabelWithCategoryValue(linkedLabelWithCategory);
    };
    const updateLinkedLabels = (category: number, label: string) => {
        request({
            addToast,
            url: `/api/users/linkedLabels/${category}/${label}`,
        })
            .then(({ data }) => {
                var resultArray = Object.keys(data.labels).map(function (
                    label
                ) {
                    let lab = data.labels[label];
                    return lab;
                });
                setListOfLinkedLabels(resultArray);
            })
            .catch();
    };

    console.log(listOfLinkedLabels);
    const updateLinkedLabelWithCategory = (
        newLinkLabelsWithCategory: any,
        amount: number,
        id: string
    ) => {
        request({
            addToast,
            url: `/api/users/linkedLabels/update/${id}`,
            method: "post",
            data: { newLinkLabelsWithCategory: newLinkLabelsWithCategory },
        })
            .then((data) => {
                // The data has been saved.
                addToast(`Le label a bien été modifié`, {
                    appearance: "success",
                });
                updateSubCategories(editingLinkedLabelWithCategory.category);
                updateLinkedLabels(
                    editingLinkedLabelWithCategory.category,
                    editingLinkedLabelWithCategory.sub_category
                );
                setEditingLinkedLabelWithCategoryValue(null);
            })
            .catch();
    };

    const createLinkedLabel = (
        mvt: number,
        category: number,
        subCategory: string,
        label: string
    ) => {
        const newLabel = capitalizeFirstLetterOnly(label);
        request({
            addToast,
            url: "/api/users/linkedLabels/store",
            method: "post",
            data: {
                mouvement: Number(mvt),
                category: Number(category),
                sub_category: subCategory,
                label: newLabel,
            },
        })
            .then((data) => {
                // The data has been saved.
                addToast(`Le label a bien été associé`, {
                    appearance: "success",
                });

                updateSubCategories(category);
                // updateLinkedLabels(category, selectedSubCategory.label);
                updateLinkedLabels(category, subCategory);
            })
            .catch();
    };

    // Linked labels functions
    const removeLinkedLabelWithCategory = (category: any) => {
        request({
            addToast,
            url: `/api/users/linkedLabels/destroy/${category._id}`,
            method: "post",
            data: {},
        })
            .then((data) => {
                // The data has been saved.
                addToast(`Le label a bien été supprimé`, {
                    appearance: "success",
                });
                updateSubCategories(category.category);
                updateLinkedLabels(category.category, category.sub_category);
            })
            .catch();
    };

    const updateSubCategoriesForSelect = (c: number) => {
        request({ addToast, url: `/api/users/subCategories/${c}` })
            .then(({ data }) => {
                let subCategoriesForSelect: Array<any> = Array();
                data.forEach(function (subCat: string, index: number) {
                    subCategoriesForSelect.push({
                        label: subCat,
                        value: String(index),
                        key: index,
                    });
                });
                setListSubCategoriesForSelect(subCategoriesForSelect);
                setSelectedSubCategory(subCategoriesForSelect[0]);
                if (searchBySelect == true) {
                    setSelectedSubCategory(subCategoriesForSelect[0]);
                    updateLinkedLabels(c, subCategoriesForSelect[0].label);
                    searchBySelect = false;
                }
            })
            .catch();
    };

    // SubCategories functions
    const removeSubCategory = (subCategory: any) => {
        const CFsubCategory = capitalizeFirstLetterOnly(subCategory);
        request({
            addToast,
            url: `/api/subCategories/destroy`,
            method: "post",
            data: {
                category: selectedCategory,
                subCategory: CFsubCategory,
            },
        })
            .then((data) => {
                // The data has been saved.
                addToast(`La sous-catégorie a bien été supprimée`, {
                    appearance: "success",
                });
                updateSubCategories(selectedCategory);
            })
            .catch();
    };

    const showEditSubcategory = (subCategory: any) => {
        setEditingSubCategoryValue(capitalizeFirstLetterOnly(subCategory));
    };

    const getSelectedCategory = (c: string) => {
        setSelectedCategory(parseInt(c));
        updateSubCategories(parseInt(c));
    };

    useEffect(() => {
        if (
            !listOfSubCategories &&
            !listSubCategoriesForSelect &&
            !listOfLinkedLabels
        ) {
            updateSubCategories(selectedCategory);
            updateSubCategoriesForSelect(selectedCategory);
        }
        if (!locks) {
            actualiseLocks();
        }
        if (listSubCategoriesForSelect != null && isFirstLoad) {
            updateLinkedLabels(0, listSubCategoriesForSelect[0].label);
            setIsFirstLoad(false);
        }
    }, [listOfSubCategories, listSubCategoriesForSelect, locks]);
    return (
        <>
            {listOfLinkedLabels && selectedSubCategory ? (
                <>
                    {listOfSubCategories &&
                        locks &&
                        listSubCategoriesForSelect &&
                        listOfLinkedLabels && (
                            <div className="bg-white p-6 rounded-lg shadow-md">
                                <div className="text-2xl font-semibold text-gray-800 mb-4 flex items-center space-x-2">
                                    <span>Gestion des Sous-Catégories</span>
                                </div>
                                <div className="flex p-2">
                                    <FormAddSubCategories
                                        getSelectedCategory={(c: string) =>
                                            getSelectedCategory(c)
                                        }
                                        submit={(
                                            category: number,
                                            subCategory: string
                                        ) =>
                                            createSubCategory(
                                                category,
                                                subCategory
                                            )
                                        }
                                    />
                                    <ListSubCategories
                                        listOfSubCategories={
                                            listOfSubCategories
                                        }
                                        remove={(l: any) =>
                                            removeSubCategory(l)
                                        }
                                        editSubCategory={(l: any) =>
                                            showEditSubcategory(l)
                                        }
                                        updateSubCategory={(
                                            newSubcategoryName: string,
                                            categoryToEdit: string
                                        ) =>
                                            updateSubCategory(
                                                newSubcategoryName,
                                                categoryToEdit
                                            )
                                        }
                                        subCategoryInEdit={
                                            editingSubCategoryValue
                                        }
                                        cancel={() =>
                                            setEditingSubCategoryValue(null)
                                        }
                                    />
                                </div>
                                {/* <Divider />
                    <LockAccount
                        locks={locks}
                        actualiseLocks={() => actualiseLocks()}
                    /> */}
                            </div>
                        )}
                    <hr className="m-4" />
                    <div className="pl-6 pr-6 pt-6 text-2xl font-semibold text-gray-800 mb-4 flex items-center space-x-2">
                        <span>
                            Relier des labels aux libellés des opérations
                            bancaires
                        </span>
                    </div>
                    <div className="flex p-2 bg-white p-6 rounded-lg shadow-md">
                        {selectedSubCategory ? (
                            <FormAddLabelWithSubCategories
                                getSubCategoriesFormLinkedLabel={(
                                    c: string,
                                    label?: string
                                ) => getSelectedLinkedSubCat(c, label)}
                                listSubCategoriesForSelect={
                                    listSubCategoriesForSelect
                                }
                                submit={(
                                    m: number,
                                    c: number,
                                    s: string,
                                    l: string
                                ) => createLinkedLabel(m, c, s, l)}
                                selectedSubCategory={selectedSubCategory}
                            />
                        ) : (
                            <Loader />
                        )}
                        {listOfLinkedLabels ? (
                            <ListLinkLabelsWithCategories
                                list={listOfLinkedLabels}
                                remove={(category: any) =>
                                    removeLinkedLabelWithCategory(category)
                                }
                                editLinkedLabelWithCategory={(
                                    l: ILinkedLabelWithCategory
                                ) => showEditLinkedLabelWithCategory(l)}
                                updateLinkedLabelWithCategory={(
                                    newLinkLabelsWithCategory: string,
                                    amount: number,
                                    id: string
                                ) =>
                                    updateLinkedLabelWithCategory(
                                        newLinkLabelsWithCategory,
                                        amount,
                                        id
                                    )
                                }
                                linkedLabelWithCategoryInEdit={
                                    editingLinkedLabelWithCategory
                                }
                                cancel={() =>
                                    setEditingLinkedLabelWithCategoryValue(null)
                                }
                            />
                        ) : (
                            <Loader />
                        )}
                    </div>
                </>
            ) : (
                <Loader />
            )}
        </>
    );
};
