export const base64ToFile = (
    base64Strings: string[],
    fileName: string
): File[] => {
    const files: File[] = [];

    if (base64Strings) {
        base64Strings.forEach((base64String, index) => {
            if (
                Object.prototype.toString.call(base64String) === "[object File]"
            ) {
                return base64String;
            }
            // Vérifie si la chaîne base64 commence par "data:[mimeType];base64,"
            if (base64String) {
                const match = base64String.match(/^data:(.+);base64,(.*)$/);
                if (!match) {
                    throw new Error("Invalid base64 string format");
                }

                // Extrait le mimeType et les données base64
                const mimeType = match[1];
                const dataBase64 = match[2];

                // Décodage de la chaîne base64
                const byteString = atob(dataBase64);
                const arrayBuffer = new ArrayBuffer(byteString.length);
                const byteArray = new Uint8Array(arrayBuffer);

                // Remplissage du tableau d'octets
                for (let i = 0; i < byteString.length; i++) {
                    byteArray[i] = byteString.charCodeAt(i);
                }

                // Création d'un Blob à partir du tableau d'octets
                const blob = new Blob([arrayBuffer], { type: mimeType });

                // Création d'un objet File à partir du Blob et en ajoutant un suffixe unique au nom de fichier
                const file = new File([blob], `${fileName}_${index + 1}`, {
                    type: mimeType,
                });

                // Ajoute le fichier à la liste
                files.push(file);
            }
        });

        return files;
    }
    return null;
};

export const getFormattedDate = (today) => {
    // Récupère le jour, le mois et l'année
    const day = String(today.getDate()).padStart(2, "0"); // Ajouter un zéro devant si nécessaire
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Les mois commencent à 0, donc on ajoute 1
    const year = today.getFullYear();

    // Formater la date
    return `${day}/${month}/${year}`;
};

export const getCategories = () => {
    return [
        // ------ RECETTES ------ //
        { label: "Revenus", label_logiciel: "Revenus", value: 0, mvt: 0 },
        {
            label: "Allocations",
            label_logiciel: "Allocations",
            value: 1,
            mvt: 0,
        },
        {
            label: "Revenus mobiliers",
            label_logiciel: "Revenus mobiliers",
            value: 2,
            mvt: 0,
        },
        {
            label: "Revenus immobiliers",
            label_logiciel: "Revenus Immobiliers",
            value: 3,
            mvt: 0,
        },
        {
            label: "Remboursements",
            label_logiciel: "Remboursements",
            value: 4,
            mvt: 0,
        },
        {
            label: "Autres ressources",
            label_logiciel: "Autres ressources",
            value: 5,
            mvt: 0,
        },
        // ------ DEPENSES ------ //
        {
            label: "Vie courante",
            label_logiciel: "Vie courante",
            value: 6,
            mvt: 1,
        },
        { label: "Logement", label_logiciel: "Logement", value: 7, mvt: 1 },
        {
            label: "Assurances",
            label_logiciel: "Frais d'assurance",
            value: 8,
            mvt: 1,
        },
        {
            label: "Impôts et taxes",
            label_logiciel: "Impôts et Taxes",
            value: 9,
            mvt: 1,
        },
        {
            label: "Achats importants",
            label_logiciel: "Achats importants",
            value: 10,
            mvt: 1,
        },
        { label: "Banque", label_logiciel: "Banque", value: 11, mvt: 1 },
        { label: "Santé", label_logiciel: "Santé", value: 12, mvt: 1 },
        {
            label: "Autres dépenses",
            label_logiciel: "Autres dépenses",
            value: 13,
            mvt: 1,
        },
    ];
};
