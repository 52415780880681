import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import IFintectureBank from "../../../interfaces/IFintectureBank";
import IUser from "../../../interfaces/IUser";
import { request } from "../../../utils/axios-utils";
import FullBarTitle from "../../layout/FullBarTitle";
import Ebics from "./Ebics";
import SyncAccountOption from "./SyncAccountOption";

const AccountingsSettings: React.FC = () => {
    const { addToast } = useToasts();

    const [syncFintectureBanks, setSyncFintectureBanks] = useState<any>(null);

    const [syncLinxoConnections, setSyncLinxoConnections] = useState<any>(null);
    const [activated, setActivated] = useState<boolean>(false);

    const [isSync, setIsSync] = useState<boolean>(false);

    const [user, setUser] = useState<IUser>(null);

    useEffect(() => {
        if (!user) {
            request({ addToast, url: `/api/user` })
                .then(({ data }) => {
                    setUser(data);

                    if (data.abonnement) {
                        setActivated(data.abonnement.activated);
                    }
                })
                .catch();
        }
    }, [user, activated]);

    useEffect(() => {
        if (!user) {
            request({ addToast, url: `/api/user` })
                .then(({ data }) => {
                    setUser(data);

                    if (data.abonnement) {
                        if (!syncFintectureBanks) {
                            getFintectureBanks();
                        }

                        if (!syncLinxoConnections) {
                            getLinxoConnections();
                        }
                    }
                })
                .catch();
        }
    }, [user, syncFintectureBanks, syncLinxoConnections, isSync]);

    //---------FINTECTURE---------//
    const removeBank = (bankId: string) => {
        request({
            addToast,
            url: `api/fintecture/remove-bank`,
            method: "post",
            data: { bank_id: bankId },
        })
            .then((res) => {
                getFintectureBanks();
            })
            .catch();
    };

    const getFintectureBanks = () => {
        request({ addToast, url: "/api/fintecture/synced-banks" })
            .then((res) => {
                if (res.data.banks !== false) {
                    setSyncFintectureBanks(res.data.banks);
                }
            })
            .catch();
    };

    const handleRenewToken = (bankId: string) => {
        request({
            addToast,
            url: `api/fintecture/renew-bank-connection/${bankId}`,
            method: "post",
            data: {},
        })
            .then((res) => {
                document.location.href = res.data;
            })
            .catch();
    };

    const handleEnableAccount = (accountId: string) => {
        request({
            addToast,
            url: `api/fintecture/enable-account`,
            method: "post",
            data: { account_id: accountId },
        })
            .then((res) => {
                getFintectureBanks();
            })
            .catch();
    };

    const goToFintectureWidget = () => {
        request({
            addToast,
            url: "api/fintecture/create-connection",
            method: "post",
            data: {},
        })
            .then((res) => {
                window.location.href = res.data;
            })
            .catch();
    };
    //----------------------------//

    //-----------LINXO-----------//
    const handleDeleteLinxoConnection = (connectionId: string) => {
        if (
            confirm(
                "Attention: La suppression est définitive. Êtes vous sûr(e) ?"
            )
        ) {
            axios
                .post(`/api/linxo/delete-connection`, {
                    connection_id: connectionId,
                })
                .then((res) => {
                    getLinxoConnections();
                });
        }
    };

    const getLinxoConnections = () => {
        axios
            .get("/api/linxo/get-connections-status")
            .then((res) => {
                setSyncLinxoConnections(res.data.connections);
            })
            .catch((data) => {
                setSyncLinxoConnections([]);
            });
    };

    const goToLinxoWidget = () => {
        axios
            .get(`/api/linxo/get-widget-session`)
            .then(({ data }) => {
                // Access to Oxlin "add connection" page

                document.location.href =
                    data._links.add_connection +
                    "&redirect_url=" +
                    process.env.REACT_APP_LINXO_REDIRECT_URL;
            })
            .catch((data) => {
                const { message } = data.response.data;
                addToast(message, { appearance: "error" });
            });
    };
    //----------------------------//

    return (
        <>
            <div className="accountings_settings">
                <div>
                    {/* Ebics */}
                    {user &&
                        user.abonnement &&
                        user.abonnement?.ebics_activated && (
                            <div className="column">
                                <FullBarTitle
                                    sticky="top-90"
                                    text="Télétransmissions bancaires EBICS"
                                />
                                <div className="page_content">
                                    <Ebics />
                                </div>
                            </div>
                        )}

                    {/* Fintecture */}
                    <div className="column">
                        <FullBarTitle
                            sticky="top-90"
                            text="Synchronisation des comptes avec Fintecture"
                            helpUrl="/pdf/TUTO_Synchronisation_Comptes_Fintecture.pdf"
                        />
                        <div className="column w-100">
                            {!syncFintectureBanks && (
                                <div className="w-100 alert alert-info text-center">
                                    Connexion en chargement
                                </div>
                            )}
                            {syncFintectureBanks &&
                                syncFintectureBanks.length === 0 && (
                                    <div className="w-100 alert alert-info text-center">
                                        Vous n'avez pas encore de connexions...
                                    </div>
                                )}
                            {syncFintectureBanks &&
                                syncFintectureBanks.length > 0 &&
                                syncFintectureBanks.map(
                                    (bank: IFintectureBank) => (
                                        <div
                                            key={bank._id}
                                            className="card_bank"
                                        >
                                            <div className="layout-space-between-center row bg-slate-200">
                                                <div className="p-2">
                                                    <b>{bank.name}</b>
                                                    {bank.status !==
                                                    "PENDING" ? (
                                                        <span
                                                            className="import_date"
                                                            style={{
                                                                marginLeft: 5,
                                                            }}
                                                        >
                                                            (Expire le{" "}
                                                            {bank.expires.date}{" "}
                                                            dans{" "}
                                                            {bank.expires.in}{" "}
                                                            jours)
                                                        </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>

                                                <div>
                                                    {bank.status === "READY" ? (
                                                        <i
                                                            className="material-icons"
                                                            style={{
                                                                color: "green",
                                                            }}
                                                        >
                                                            done
                                                        </i>
                                                    ) : (
                                                        <i
                                                            style={{
                                                                color: "red",
                                                            }}
                                                            className="material-icons cursor-pointer"
                                                        >
                                                            sync_problem
                                                        </i>
                                                    )}
                                                    <i
                                                        className="material-icons cursor-pointer"
                                                        style={{
                                                            color: "grey",
                                                        }}
                                                        onClick={() =>
                                                            removeBank(bank._id)
                                                        }
                                                    >
                                                        delete_outline
                                                    </i>
                                                </div>
                                            </div>
                                            {(bank.status === "TOKEN_EXPIRED" ||
                                                bank.status === "RENEWING") && (
                                                <div
                                                    className="alert alert-danger pointer"
                                                    onClick={() =>
                                                        handleRenewToken(
                                                            bank._id
                                                        )
                                                    }
                                                >
                                                    L'accès à votre banque à
                                                    expiré. Merci de cliquer ici
                                                    pour renouveler la connexion
                                                    !
                                                </div>
                                            )}
                                            {bank.status !== "TOKEN_EXPIRED" &&
                                                bank.status !== "RENEWING" &&
                                                90 -
                                                    moment().diff(
                                                        bank.opened_at,
                                                        "days"
                                                    ) <
                                                    5 && (
                                                    <div
                                                        className="alert alert-danger pointer"
                                                        onClick={() =>
                                                            handleRenewToken(
                                                                bank._id
                                                            )
                                                        }
                                                    >
                                                        L'accès à votre banque
                                                        va expirer dans{" "}
                                                        {90 -
                                                            moment().diff(
                                                                bank.opened_at,
                                                                "days"
                                                            )}{" "}
                                                        jour(s). Merci de
                                                        cliquer ici pour
                                                        renouveler la connexion
                                                        !
                                                    </div>
                                                )}
                                            {bank.status === "ERROR" && (
                                                <div className="alert alert-danger pointer">
                                                    Nous avons rencontré une
                                                    anomalie avec cette banque
                                                    et en avons été alerté. Nous
                                                    faisons notre maximum pour
                                                    la résoudre.
                                                </div>
                                            )}
                                            {bank.status ===
                                                "SYNCHRONIZING" && (
                                                <div className="alert alert-info">
                                                    Les comptes de cette banque
                                                    sont en cours de
                                                    synchronisation... Ils
                                                    seront disponibles dans
                                                    quelques minutes.
                                                </div>
                                            )}
                                            {bank.status != "ERROR" &&
                                                bank.accounts.map((a: any) => {
                                                    if (
                                                        a.name ===
                                                        "M.OU MME DJAHNINE LOUGNON"
                                                    ) {
                                                        return null;
                                                    }
                                                    return (
                                                        <div
                                                            key={a._id}
                                                            className="account row m-1"
                                                        >
                                                            <div className="content-center">
                                                                {a.gerancia_account_id ? (
                                                                    <i
                                                                        className="material-icons"
                                                                        style={{
                                                                            color: "green",
                                                                        }}
                                                                    >
                                                                        sync
                                                                    </i>
                                                                ) : (
                                                                    <i
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                        className="material-icons"
                                                                    >
                                                                        sync_disabled
                                                                    </i>
                                                                )}
                                                            </div>
                                                            <div
                                                                className={
                                                                    a.enabled_at
                                                                        ? "leading-9 stroke"
                                                                        : "leading-9 line-through"
                                                                }
                                                            >
                                                                {a.gerancia_account_id &&
                                                                    a.gerancia_account && (
                                                                        <b>
                                                                            {
                                                                                a
                                                                                    .gerancia_account
                                                                                    .protected_adult
                                                                                    .first_name
                                                                            }{" "}
                                                                            {
                                                                                a
                                                                                    .gerancia_account
                                                                                    .protected_adult
                                                                                    .last_name
                                                                            }{" "}
                                                                            -
                                                                            Compte{" "}
                                                                            {
                                                                                a
                                                                                    .gerancia_account
                                                                                    .number
                                                                            }
                                                                        </b>
                                                                    )}
                                                                {!a.gerancia_account_id &&
                                                                    a.name}
                                                                <span
                                                                    className="import_date text-xs"
                                                                    style={{
                                                                        marginLeft: 20,
                                                                    }}
                                                                >
                                                                    (Dernière
                                                                    synchronisation
                                                                    :{" "}
                                                                    {
                                                                        a.synced_date
                                                                    }
                                                                    )
                                                                </span>
                                                            </div>
                                                            {a.gerancia_account_id && (
                                                                <button
                                                                    className={
                                                                        !a.enabled_at
                                                                            ? "cursor-pointer bg-transparent hover:bg-green-600 text-red-600 font-semibold hover:text-white py-2 px-4 border border-red-600 hover:border-transparent rounded"
                                                                            : "cursor-pointer bg-transparent hover:bg-red-600 text-green-600 font-semibold hover:text-white py-2 px-4 border border-green-600 hover:border-transparent rounded"
                                                                    }
                                                                    style={{
                                                                        marginLeft: 10,
                                                                    }}
                                                                    onClick={() =>
                                                                        handleEnableAccount(
                                                                            a._id
                                                                        )
                                                                    }
                                                                >
                                                                    {!a.enabled_at
                                                                        ? "Inactif"
                                                                        : "Actif"}
                                                                </button>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    )
                                )}
                            <div className="row layout-center-center">
                                <button
                                    type="button"
                                    className="m-2 cursor-pointer bg-transparent hover:bg-green-600 text-green-600 font-semibold hover:text-white py-2 px-4 border border-green-600 hover:border-transparent rounded"
                                    onClick={() => goToFintectureWidget()}
                                >
                                    Ajouter une connexion avec Fintecture
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Linxo */}
                    <div className="column">
                        <div className="column w-100">
                            <FullBarTitle
                                sticky="top-90"
                                text="Connexions ouvertes avec Linxo"
                            />
                            {!syncLinxoConnections && (
                                <div className="w-100 alert alert-info text-center">
                                    Chargement de vos connexions et comptes en
                                    cours...
                                </div>
                            )}
                            {syncLinxoConnections &&
                                syncLinxoConnections.length === 0 && (
                                    <div className="w-100 alert alert-info text-center">
                                        Vous n'avez pas encore de connexions...
                                    </div>
                                )}
                            {syncLinxoConnections &&
                                syncLinxoConnections.length > 0 &&
                                syncLinxoConnections.map((connection: any) => (
                                    <div
                                        key={connection.id}
                                        className="card_bank"
                                    >
                                        <div className="layout-space-between-center row bg-slate-200">
                                            <div className="w-full">
                                                <div className="flex justify-between p-2">
                                                    <div>
                                                        <b>{connection.name}</b>
                                                        <br />
                                                        Propriétaire :{" "}
                                                        {connection.owner.name}
                                                    </div>
                                                    <div>
                                                        {
                                                            <span className="leading-9">
                                                                {
                                                                    connection.status
                                                                }
                                                            </span>
                                                        }
                                                        <i
                                                            className="material-icons cursor-pointer"
                                                            style={{
                                                                color: "grey",
                                                            }}
                                                            onClick={() =>
                                                                handleDeleteLinxoConnection(
                                                                    connection.id
                                                                )
                                                            }
                                                        >
                                                            delete_outline
                                                        </i>
                                                    </div>
                                                </div>
                                                {connection.accounts.map(
                                                    (a: any) => (
                                                        <div
                                                            key={a.id}
                                                            className="account row bg-white"
                                                        >
                                                            <div
                                                                className={
                                                                    !a.enabled_at &&
                                                                    !a.isSync
                                                                        ? "stroke account_content leading-9 m-1 "
                                                                        : !a.enabled_at &&
                                                                          a.isSync
                                                                        ? "leading-9"
                                                                        : "line-through"
                                                                }
                                                            >
                                                                <b>
                                                                    Compte n°{" "}
                                                                    {a.account_number +
                                                                        " - "}
                                                                    {a.name}
                                                                </b>
                                                            </div>
                                                            <SyncAccountOption
                                                                accountNumber={
                                                                    a.account_number
                                                                }
                                                                accountIsSync={(
                                                                    isSync: boolean
                                                                ) => {
                                                                    a.isSync =
                                                                        isSync;
                                                                    setIsSync(
                                                                        isSync
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            <div className="row layout-center-center">
                                <button
                                    type="button"
                                    className="m-2 cursor-pointer bg-transparent hover:bg-green-600 text-green-600 font-semibold hover:text-white py-2 px-4 border border-green-600 hover:border-transparent rounded"
                                    onClick={() => goToLinxoWidget()}
                                >
                                    Ajouter une connexion avec Linxo
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default AccountingsSettings;
