import React, { useState } from "react";
import {
    capitalizeFirst,
    CATS,
    MVT_WITHOUTH_AMOUNT,
} from "../../../constants/Utils";
import InputComponent from "../../layout/forms/InputComponent";
import Select from "../../layout/forms/Select";

const FormAddSubCategories: React.FC<{
    getSelectedCategory: Function;
    submit: Function;
}> = (props) => {
    const [categories, setCategories] = useState<Array<any>>(
        CATS.filter((c) => c.mvt === 0)
    );
    const [textInput, setTextInput] = useState<string>("");
    const [selectedMouvment, setSelectedMouvment] = useState<string>(
        MVT_WITHOUTH_AMOUNT[0].value
    );
    const [selectedCategory, setSelectedCategory] = useState<string>(
        categories[0].value
    );

    const submit = (e: any) => {
        e.preventDefault();
        if (textInput) {
            props.submit(selectedCategory, capitalizeFirst(textInput.trim()));
            setTextInput("");
        }
    };

    const onChangeMouvement = (option: any) => {
        let newCategories = CATS.filter(
            (c) => c.mvt === parseInt(option.value)
        );
        setCategories(newCategories);
        setSelectedMouvment(option.value);
        setSelectedCategory(newCategories[0].value);
        props.getSelectedCategory(newCategories[0].value);
    };

    const onChangeCategory = (option: any) => {
        setSelectedCategory(option.value);
        props.getSelectedCategory(option.value);
    };

    return (
        <>
            <form className="space-y-2 mr-4 mb-4">
                <Select
                    id="mouvment"
                    label="Mouvement"
                    required
                    options={MVT_WITHOUTH_AMOUNT}
                    value={selectedMouvment}
                    onChange={(e) =>
                        onChangeMouvement(e.target.selectedOptions[0])
                    }
                    className="w-full min-w-[250px]" // Taille fixe pour tous les dropdowns
                />

                <Select
                    id="category"
                    label="Catégorie"
                    required
                    options={categories}
                    value={selectedCategory}
                    onChange={(e) =>
                        onChangeCategory(e.target.selectedOptions[0])
                    }
                    className="w-full min-w-[250px]" // Taille fixe pour tous les dropdowns
                />

                <div className="">
                    <InputComponent
                        id="idSubCat"
                        label="Nouvelle sous catégorie"
                        required
                        value={textInput}
                        onChange={(e) => setTextInput(e.target.value)}
                        maxLength={50}
                        className="w-full border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                </div>

                <div className="flex justify-center">
                    <button
                        type="submit"
                        onClick={(e) => submit(e)}
                        className=" w-full py-2 px-6 bg-green-600 text-white font-semibold rounded-md hover:bg-green-700 transition duration-200"
                    >
                        Ajouter
                    </button>
                </div>
            </form>
        </>
    );
};

export default FormAddSubCategories;
