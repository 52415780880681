import React, { useState } from "react";
import {
    capitalizeFirst,
    CATS,
    MVT_WITHOUTH_AMOUNT,
} from "../../../constants/Utils";
import InputComponent from "../../layout/forms/InputComponent";
import Select from "../../layout/forms/Select";

const FormAddLabelWithSubCategories: React.FC<{
    getSubCategoriesFormLinkedLabel: Function;
    listSubCategoriesForSelect: Array<any>;
    submit: Function;
    selectedSubCategory: any;
}> = (props) => {
    const [textInput, setTextInput] = useState<string>("");
    const [amount, setAmount] = useState<string>("");
    const [isLabelFromSubCat, setIsLabelFromSubCat] = useState<boolean>(false);
    const [selectedMouvment, setSelectedMouvment] = useState<string>(
        MVT_WITHOUTH_AMOUNT[0].value
    );
    const [categories, setCategories] = useState<Array<any>>(
        CATS.filter((c) => c.mvt === 0)
    );
    const [selectedCategory, setSelectedCategory] = useState<string>(
        categories[0].value
    );
    const [selectedSubCategory, setSelectedSubCategory] = useState<string>(
        props.selectedSubCategory.value
    );
    const [selectedSubCategoryName, setSelectedSubCategoryName] =
        useState<string>(props.selectedSubCategory.label);

    const onChangeMouvement = (option: any) => {
        let newCategories = CATS.filter(
            (c) => c.mvt === parseInt(option.value)
        );
        setSelectedMouvment(option.value);
        setCategories(newCategories);
        setSelectedCategory(newCategories[0].value);
        props.getSubCategoriesFormLinkedLabel(newCategories[0].value);
    };

    const onChangeCategory = (option: any) => {
        setSelectedCategory(option.value);
        props.getSubCategoriesFormLinkedLabel(option.value);
    };

    const onChangeSubCategory = (option: any) => {
        setSelectedSubCategory(option.value);
        props.getSubCategoriesFormLinkedLabel(selectedCategory, option.label);
    };

    const submit = (e: any) => {
        e.preventDefault();
        if (textInput) {
            props.submit(
                selectedMouvment,
                selectedCategory,
                isLabelFromSubCat
                    ? selectedSubCategoryName
                    : props.selectedSubCategory.label,
                capitalizeFirst(textInput.trim())
            );
        }
    };

    return (
        <>
            <form className="space-y-2">
                <div className="space-y-2">
                    <Select
                        id="mouvment"
                        label="Mouvement"
                        required
                        options={MVT_WITHOUTH_AMOUNT}
                        value={selectedMouvment}
                        onChange={(e) => {
                            setIsLabelFromSubCat(false);
                            onChangeMouvement(e.target.selectedOptions[0]);
                            setSelectedSubCategory(
                                props.selectedSubCategory.value
                            );
                        }}
                        className="w-full min-w-[250px]" // Taille fixe pour tous les dropdowns
                    />
                </div>

                <div className="space-y-2">
                    <Select
                        id="category"
                        label="Catégorie"
                        required
                        options={categories}
                        value={selectedCategory}
                        onChange={(e) => {
                            setIsLabelFromSubCat(false);
                            onChangeCategory(e.target.selectedOptions[0]);
                            setSelectedSubCategory(
                                props.selectedSubCategory.value
                            );
                        }}
                        className="w-full min-w-[250px]" // Taille fixe pour tous les dropdowns
                    />
                </div>

                <div className="space-y-2">
                    <Select
                        id="subCategory"
                        label="Sous Catégorie"
                        required
                        options={props.listSubCategoriesForSelect}
                        value={selectedSubCategory}
                        onChange={(e) => {
                            setIsLabelFromSubCat(true);
                            onChangeSubCategory(e.target.selectedOptions[0]);
                            setSelectedSubCategoryName(
                                e.target.selectedOptions[0].label
                            );
                        }}
                        className="w-full min-w-[250px]" // Taille fixe pour tous les dropdowns
                    />
                </div>

                <InputComponent
                    id="label"
                    label="Label à associer"
                    required
                    value={textInput}
                    onChange={(e) => setTextInput(e.target.value)}
                    maxLength={50}
                    className="w-full border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                />

                <div className="flex justify-center">
                    <button
                        type="submit"
                        onClick={(e) => submit(e)}
                        className="mt-2 w-full py-2 px-6 bg-green-600 text-white font-semibold rounded-md hover:bg-green-700 transition duration-200"
                    >
                        Ajouter
                    </button>
                </div>
            </form>
        </>
    );
};

export default FormAddLabelWithSubCategories;
