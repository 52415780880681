import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { request } from "../../../utils/axios-utils";
import Modal from "../../layout/modals/Modal";

const LockAccount: React.FC<{
    locks: Array<boolean>;
    actualiseLocks: Function;
    accountId?: string;
}> = (props) => {
    const { addToast } = useToasts();

    const [confirmClosure, setConfirmClosure] = useState<boolean>(false);
    const [yearSelected, setYearSelected] = useState<number>(2014);

    const getYears = () => {
        const years = [];
        const current = new Date().getFullYear();
        const first = 2014;
        for (let i = first; i <= current; i += 1) {
            years.push(i);
        }
        return years;
    };

    /**
     * Closure of a year
     */
    const closure = (value: boolean, year: number) => {
        // To close account or all accounts
        if (value == true) {
            // If it is for all user accounts
            if (!props.accountId) {
                request({
                    addToast,
                    url: `/api/accounts/lockAll/${year}`,
                    method: "post",
                    data: {},
                })
                    .then((data) => {
                        addToast(
                            `Les comptes de l'année ${year} ont bien été clôturés.`,
                            { appearance: "success" }
                        );
                        setConfirmClosure(false);
                        props.actualiseLocks();
                    })
                    .catch();
                // If it is just for one account
            } else {
                request({
                    addToast,
                    url: `/api/accounts/lock/${year}/${props.accountId}`,
                    method: "post",
                    data: {},
                })
                    .then((data) => {
                        addToast(`L'année ${year} a bien été clôturée.`, {
                            appearance: "success",
                        });
                        setConfirmClosure(false);
                        props.actualiseLocks();
                    })
                    .catch();
            }
        }
        // To unclosed one or all accounts
        else {
            if (!props.accountId) {
                addToast(
                    "Vous ne pouvez pas déverouiller une année complète, vous pouvez le faire compte par compte.",
                    { appearance: "error" }
                );
            } else {
                request({
                    addToast,
                    url: `/api/accounts/${year}/${props.accountId}/unlock`,
                    method: "post",
                    data: {},
                })
                    .then((data) => {
                        addToast(`L'année ${year} n'est plus clôturée.`, {
                            appearance: "success",
                        });
                        setConfirmClosure(false);
                        props.actualiseLocks();
                    })
                    .catch();
            }
        }
    };

    return (
        <>
            <Modal
                message={`Etes-vous sûr de vouloir clôturer définitivement l'année ${yearSelected} ?`}
                show={confirmClosure}
                onValidate={() => {
                    setConfirmClosure(false);
                    closure(true, yearSelected);
                }}
                onClose={() => {
                    setConfirmClosure(false);
                }}
            />
            {/* {props.locks && (
                <div>
                    <FullBarTitle
                        text={
                            (props.accountId && "Clôturer ce compte") ||
                            "Clôturer tous les comptes"
                        }
                    />
                    <div className="page_content">
                        {getYears().map((year) => (
                            <div style={{ marginTop: 5 }}>
                                <InputSwitch
                                    id={"switch"}
                                    className="column-item-flex-1"
                                    onText="Cloturé"
                                    offText="Non cloturé"
                                    label={year.toString()}
                                    value={!!props.locks[year]}
                                    showModal={() => {
                                        setYearSelected(year);
                                        setConfirmClosure(true);
                                    }}
                                    allAccounts={
                                        !props.accountId ? true : false
                                    }
                                    modalSwitch={true}
                                    onChange={(e: any) => {
                                        setYearSelected(year);
                                        closure(e, year);
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )} */}
        </>
    );
};
export default LockAccount;
