import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { useToasts } from "react-toast-notifications";
import { ToolBarSettings } from "../../../constants/SettingsItems";
import ISettingsItem from "../../../interfaces/ISettingsItem";
import IUser from "../../../interfaces/IUser";
import { request } from "../../../utils/axios-utils";
import SubBar from "../../layout/SubBar";
import AccountingsSettings from "./AccountingsSettings";
import CloudSettings from "./CloudSettings";
import InfosSettings from "./InfosSettings";
import PartnerSettings from "./Partner/PartnerSettings";
import TrainingSettings from "./TrainingSettings";

import InvoicesSettings from "./InvoicesSettings";
import MailSettings from "./MailSettings";

const Settings: React.FC = () => {
    const location = useLocation();
    const { addToast } = useToasts();

    const [user, setUser] = useState<IUser>(null);
    const [role, setRole] = useState<string>(null);
    const [items, setItems] = useState(null);
    const { module }: { module?: string } = useParams();

    const [moduleSelected, setModuleSelected] = useState<string>(null);

    const history = useHistory();

    useEffect(() => {
        if (!user) {
            request({ addToast, url: `/api/user` })
                .then(({ data }) => {
                    setUser(data);
                    setRole(data.roles[0]);

                    const newItems = ToolBarSettings.filter(
                        (x: ISettingsItem) =>
                            x.roles.indexOf(data.roles[0]) > -1
                    );

                    if (!items) {
                        if (module) {
                            setModuleSelected(module);
                            setItems(newItems);
                            history.push(`/settings/${module}`);
                        } else {
                            setModuleSelected(newItems[0]._id);
                            setItems(newItems);
                            history.push(`/settings/${newItems[0]._id}`);
                        }
                    }
                })
                .catch();
        }

        if (location.state) {
            setModuleSelected(String(location.state));
        }
    }, [user, role]);

    return (
        <>
            {user && items && items.length !== 0 && (
                <div className="settings">
                    <SubBar title="Vos paramètres"></SubBar>

                    <div className="tool_bar_menu_container sticky top-40 no_print">
                        <div className="toolbar_menu layout-space-around-center row">
                            {items.map((item: ISettingsItem, index: any) => (
                                <button
                                    onClick={() => {
                                        setModuleSelected(item._id),
                                            history.push(
                                                `/settings/${item._id}`
                                            );
                                    }}
                                    className={`toolbar_menu_item column pointer layout-center-center ${
                                        moduleSelected == item._id
                                            ? "selected"
                                            : ""
                                    }`}
                                >
                                    <i className="material-icons">
                                        {item.icon}
                                    </i>
                                    <span className="title-action">
                                        {item.title}
                                    </span>
                                </button>
                            ))}
                        </div>
                    </div>

                    {moduleSelected === "infos" && (
                        <InfosSettings user={user} />
                    )}
                    {moduleSelected === "mails" && <MailSettings />}
                    {moduleSelected === "cloud" && <CloudSettings />}
                    {moduleSelected === "compta" && <AccountingsSettings />}

                    {moduleSelected === "formations" && <TrainingSettings />}
                    {moduleSelected === "partenariat" && <PartnerSettings />}
                    {moduleSelected === "abo" && <InvoicesSettings />}
                </div>
            )}
        </>
    );
};
export default Settings;
